// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,700|Shadows+Into+Light');

$primary: #1b3a6c; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body{
	font-family: 'Josefin Sans', sans-serif;

}
h1, h2, h3, h4, h5, h6 {
	font-family: 'Shadows Into Light', cursive;

}
.top-pad{
	font-size: 18px;
}
nav {
	z-index: 1000;
}
.navbar {
	margin:0;
}
.top-pad {
	margin-top: 120px;
	@media (max-width: 767px){
		margin-top: 85px;
	}
}
.logo {
	@media(max-width: 767px){
		max-height: 70px;
	}
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;



		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
				font-weight: bold;
				@media(max-width: 767px){
						background:transparent;
						color: darken($primary,20%);

				}
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}
.cta {
	background: $primary;
	color:lighten($primary, 55%);
	padding: 20px 25px;
	transition: all 0.7s ease 0s;
	text-transform: uppercase;
	font-size: 1.1em;
	border-radius: 0;
	margin:5px;

	&:hover{
		color:$primary;
		background: lighten($primary, 55%);
		box-shadow: 0px 0px 3px $wht;
	}
	@media (max-width: 767px){
		font-size: .8em;
		margin:2px;
	}
}
hr.style15 {
border-top: 4px double $wht;
text-align: center;

}
hr.style15:after {
max-width: 350px;
content: '\002665';
display: inline-block;
position: relative;
top: -15px;
padding: 0px 20px;
background: $wht;
color: $primary;
font-size: 18px;
}
section {
	position: relative;
	padding: 350px 0;
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	@media(max-width: 1024px){
		background-attachment: scroll;
		padding: 250px 0;
	}
	@media(max-width: 767px){
		background-attachment: scroll;
		padding: 150px 0;
	}
}
.section-a  {
	background-image: url('../img/img1.jpg');
	// margin-top: 100px;

	h1{
		font-size: 3em;
		font-weight: bolder;
		line-height: 1.2em;
		text-transform: uppercase;
		color: $wht;
		text-shadow: 0px 0px 6px darken($primary,45%);
		@media(max-width: 767px){
			font-size: 2em;
		}
	}
}
.section-b  {
	background-image: url('../img/img2.jpg');
}
.section-c  {
	background-image: url('../img/img3.jpg');
	padding: 50px 0;
}
.section-d  {
	background-image: url('../img/img4.jpg');
	h1{
		font-size: 4em;
		font-weight: bolder;
		line-height: 1.2em;
		text-transform: uppercase;
		color: $primary;
		text-align: center;
		text-shadow: 0px 0px 9px lighten($primary,85%);
		@media(max-width: 767px){
			font-size: 2em;
		}
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 20px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
